import { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useQuery } from 'apollo-client'

import { constants } from 'helpers'
import type { CollectionGroup } from 'typings/graphql'

import collectionsQuery, { type CollectionsVariables, type CollectionsPayload } from './graph/collections.graphql'

import messages from './messages'


export type CollectionItem = CollectionsPayload['CAPSULE']['data'][number] & { onClick?: () => void }

type Items = CollectionItem[]

export type Group = {
  id: string
  title?: Intl.Message
  description?: Intl.Message
  items: Items
}

const getCollectionListItems = (items: Items, excludeId: number): Items => {
  if (!items?.length) {
    return []
  }

  // business requirement - we show only 3 items per group SB-3297 SB-3300
  const truncItems = items.slice(0, 3)

  if (excludeId) {
    return truncItems.filter(({ id }) => id !== excludeId)
  }

  return truncItems
}

const COLLECTIONS_GROUPS: CollectionGroup[] = [ 'CAPSULE', 'CURATED' ]

export type useCollectionsProps = {
  gender: CollectionsVariables['gender']
  group?: CollectionGroup
  excludeId?: number
  skip?: boolean
}

// Returns collections separated by group with group information
const useCollections = ({ excludeId, gender, group, skip }: useCollectionsProps) => {
  const areCapsuleCollectionsEnabled = useFeatureIsOn(constants.features.capsuleCollections)

  const { data, error, isFetching } = useQuery(collectionsQuery, {
    variables: {
      gender,
      withCapsuleCollections: areCapsuleCollectionsEnabled,
    },
    skip,
    context: {
      ssrCache: true,
    },
  })

  const groups = useMemo<Group[]>(() => {
    const collectionsGroups = group ? [ group ] : COLLECTIONS_GROUPS

    return collectionsGroups.map((id: CollectionGroup) => ({
      id: id,
      ...messages[id],
      items: getCollectionListItems(data?.[id]?.data, excludeId),
    })).filter((group) => {
      const { items } = group

      return items.length > 0
    })
  }, [ data, excludeId, group ])

  return {
    groups,
    error,
    isFetching,
  }
}


export default useCollections
